import React, { useState } from "react"

const FacebookReviewsCard = ({ item, idx }) => {
  const [readMore, setReadMore] = useState(item.review.length > 300)
  return (
    <span>
      {readMore ? `${item.review.substring(0, 300)}...` : item && item.review}
      {item.review.length > 300 && (
        <span
          style={{
            cursor: `pointer`,
            textDecoration: `underline`,
            marginLeft: "5px",
            color: "#1588bf",
          }}
          onClick={() => setReadMore(!readMore)}
        >
          {!readMore ? "Show Less" : "Read More"}
        </span>
      )}
    </span>
  )
}

export default FacebookReviewsCard
