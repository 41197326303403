import React from "react"
import { Card, CardTitle, CardText, CardBody } from "reactstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BackToTop from "../components/back-to-top"
import Swiper from "../components/swiper/swiper"
import Allreviews from "../reviews/reviewsData"
import ReviewsCard from "../components/swiper/ReviewsCard"
import FBlogo from "../images/fbLogo.png"
import Googlelogo from "../images/googleLogo.png"
import Yelplogo from "../images/yelpLogo.png"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Hero from "../components/hero-adjusted/hero"
import review from "../images/reviewVPic.jpg"

const Reviews = ({ location }) => {
  const facebookReviews = Allreviews.filter(
    (fb) => fb.category === "Facebook Reviews"
  )
  const googleReviews = Allreviews.filter(
    (fb) => fb.category === "Google Reviews"
  )
  const yelpReviews = Allreviews.filter((fb) => fb.category === "Yelp Reviews")

  return (
    <Layout>
      <Seo title="Reviews" />
      {/* <div className="hero-adjustment">
        <div className="container">
          <div className="hero-content-wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-7">
                <div className="hero-dog-bg">
                  <div className="dog-bg"></div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="hero-video">
                  <div>
                    <div>
                      <div className="video-modal-btn no-video">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/tplo-video.jpg"
                          alt="video"
                          className="video-img img-fluid v-h"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="inner-page-title text-right arthroscopy-hero noVideoInner">
              <h1>Reviews</h1>
            </div>
          </div>
        </div>
      </div> */}
      <div className="tplo-hero newPicModal">
        <Hero
          customPlayButton={true}
          location={location}
          videoImg={review}
          pageTitle="Reviews"
        />
      </div>
      <div className="container reviews-slider py-5">
        <h2 className="mb-4 text-blue text-center">
          <LazyLoadImage effect="blur" width={50} src={FBlogo} alt="logo" />{" "}
          Facebook Reviews (
          {/* {facebookReviews.length} temporarily static data count */}
          144 )
        </h2>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <Swiper slidesperview={2}>
              {facebookReviews.map((fbreview, idx) => (
                <Card key={idx}>
                  <CardBody>
                    <CardText className="mb-2 font-italic w-300">
                      <ReviewsCard item={fbreview} />
                    </CardText>
                    <CardTitle className="text-blue mt-4">
                      <h4>{fbreview.name}</h4>
                    </CardTitle>
                  </CardBody>
                </Card>
              ))}
            </Swiper>
          </div>
          <div className="col-md-12 mt-5">
            <h2 className="mb-4 text-blue text-center">
              <LazyLoadImage
                effect="blur"
                width={50}
                src={Googlelogo}
                alt="logo"
              />{" "}
              Google Reviews (
              {/* {googleReviews.length} temporarily static data count */} 307 )
            </h2>
            <Swiper slidesperview={2}>
              {googleReviews.map((googleReview, idx) => (
                <Card key={idx}>
                  <CardBody>
                    <CardText className="mb-2 font-italic w-300">
                      <ReviewsCard item={googleReview} />
                    </CardText>
                    <CardTitle className="text-blue mt-4">
                      <h4>{googleReview.name}</h4>
                    </CardTitle>
                  </CardBody>
                </Card>
              ))}
            </Swiper>
          </div>
          <div className="col-md-12 mt-5">
            <h2 className="mb-4 text-blue text-center">
              <LazyLoadImage
                effect="blur"
                width={50}
                src={Yelplogo}
                alt="logo"
              />{" "}
              Yelp Reviews (
              {/* {yelpReviews.length} temporarily static data count */} 24 )
            </h2>
            <Swiper slidesperview={2}>
              {yelpReviews.map((yelpReview, idx) => (
                <Card key={idx}>
                  <CardBody>
                    <CardText className="mb-2 font-italic w-300">
                      <ReviewsCard item={yelpReview} />
                    </CardText>
                    <CardTitle className="text-blue mt-4">
                      <h4>{yelpReview.name}</h4>
                    </CardTitle>
                  </CardBody>
                </Card>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <BackToTop />
    </Layout>
  )
}

export default Reviews
